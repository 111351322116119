import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Button from 'components/btn';
import Title from 'components/title';
import Gallery from 'components/gallery';
import IOExample from 'components/io-example';
import Modal from 'containers/modal';
import { graphql, StaticQuery } from 'gatsby';
import Hero from '../images/images-js/hero';
import Offer from 'components/offer';
import Success from '../images/images-js/success';
import GoogleMap from '../components/map/google-map';
import FrontFace from '../images/images-js/frontFace';
import { Link } from 'gatsby';
import { gsap } from "gsap";
import { useEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {BrowserView, MobileView} from 'react-device-detect';
import Img from 'gatsby-image';

gsap.registerPlugin(ScrollTrigger);
const Index = ({ data }) =>{
  // const ref = useRef(null);
  // useEffect(() => {
  //   const element = ref.current;
  //   gsap.fromTo(
  //     element.querySelector(".about__headline"),
  //     {
  //       y: 0,
  //       duration: 3,
  //       ease: "all"
  //     },
  //     {
  //       y: -500,
  //       x: 200,
  //       duration: 3,
  //       ease: "all",
  //       scrollTrigger: {
  //         trigger: element.querySelector(".about__headline"),
  //         start: "top -50px",
  //         end: "bottom center",
  //         scrub: true
  //       }
  //     }
  //   );
  // });
  return(
    <Layout>
      <div className="header-content">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="header__content col-12 col-lg-9">
              <h1>ADW. BARTOSZ BATOR KANCELARIA ADWOKACKA</h1>
              <BrowserView>
              <Title as="h2" size="large">
                Zespół wykwalifikowanych prawników i doradców – pod kierownictwem
                doświadczonego adwokata Bartosza Batora - <strong>zajmuje się szeroko
                rozumianym doradztwem prawnym w zakresie porządkowania spraw
                majątkowych osób fizycznych oraz małych i średnich
                przedsiębiorców.</strong> Pomagamy klientom z Warszawy, ale także z Mazowsza i całej Polski.  W obszarze naszych głównych zainteresowań jest
                <strong> tematyka prawa nieruchomości (Warszawa, Mazowsze ale i reszta regionów kraju).</strong> Zajmujemy się także sprawami z
                zakresu <strong> prawa spadkowego i rodzinnego a także tzw. powództwami
                frankowymi.</strong> Oferujemy pomoc prawną zarówno w postępowaniu
                pozasądowym (mediacje i negocjacje), jak i sądowym we wszystkich
                instancjach w Warszawie oraz na Mazowszu ale i pozostałych częściach Polski.
              </Title>
              </BrowserView>
              <MobileView>
              <Title as="h2" size="large">
                Zespół wykwalifikowanych prawników i doradców – pod kierownictwem
                doświadczonego adwokata Bartosza Batora - <strong>zajmuje się szeroko
                rozumianym doradztwem prawnym w zakresie porządkowania spraw
                majątkowych osób fizycznych oraz małych i średnich
                przedsiębiorców. </strong>
              </Title>
              </MobileView>
              <div className="header-content__buttons">
                <Button
                  text="USŁUGI"
                  link="/uslugi"
                  style={{
                    background: 'black',
                    color: 'white',
                    border: '1px solid black',
                  }}
                />
                <Button
                  text="KONTAKT"
                  link="/kontakt"
                  style={{
                    background: 'white',
                    color: 'black',
                    border: '1px solid black',
                  }}
                />
              </div>
              {/* <Modal>
          <video
            src="https://i.imgur.com/gzFqNSW.mp4"
            playsInline
            loop
            autoPlay
            muted
          />
        </Modal> */}
            </div>
            <div className="header__hero">
              <Hero />
            </div>
          </div>
        </div>
      </div>
      {/* <Gallery items={data.homeJson.gallery} />
      <div style={{ height: '50vh' }} />
      <IOExample /> */}
      <section className="about d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="about__logo">
              <img src="/pictures/logo-new-white.png" alt="logo" />
            </div>
            <div className="about__picture col-lg-3" >
              <FrontFace />
            </div>
            <div className="about__content col-lg-8">
              <div className="about__headline block-headline">
                <h3>O MNIE</h3>
              </div>
              <div className="about__text">
                <p>
                  Obecnie w obszarze jego zainteresowań jest doradztwo prawne w
                  zakresie porządkowania spraw majątkowych osób fizycznych oraz
                  małych i średnich przedsiębiorców. W szczególności zajmuje się
                  sprawami z zakresu prawa nieruchomości, spadkowego, rodzinnego i
                  powództwami frankowymi.
                </p>
              </div>
              <div className="about__button">
                <Link to="/o-mnie">
                  <Button
                    text="WIĘCEJ"
                    style={{
                      background: 'transparent',
                      color: 'white',
                      border: '1px solid white',
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="offer">
        <div className="container">
          <div className="row">
            <div className="offer__headline block-headline">
              <h3>OBSZAR NASZEJ DZIAŁALNOŚCI</h3>
            </div>
            <Offer items={data.allOfferJson.edges[0].node.items} />
          </div>
        </div>
      </section>
      <section className="success">
        <div className="container-fluid">
          <div className="row">
            <Success />
            <div className="success__text">
              <h2>
                Zapraszamy do skorzystania <br /> z naszych usług
              </h2>
              {/* <span className="mt-7">BARTOS BATOR PODPIS</span> */}
            </div>
          </div>
        </div>
      </section>
      <section className="training">
        <div className="container">
          <div className="row">
            <div className="training__headline block-headline">
              <h3>SZKOLENIA</h3>
              <p>
                Oferujemy szkolenia z wybranych dziedzin prawa, dostosowane do
                konkretnych potrzeb Klientów. W szczególności oferujemy szkolenia
                z zakresu:
              </p>
            </div>
            <div className="training__items">
              <div className="training__list">
                <span>Prawa nieruchomości</span>
                <span>Prawa autorskiego i prasowego</span>
                <span>Dostępu do informacji publicznej</span>
                <span>Prawa cywilnego</span>
                <span>Prawa administracyjnego i sądowoadministracyjnego</span>
              </div>
              <div className="training__icon">
                <img src="pictures/szkolenia.svg" alt="ikona treningowa" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog">
        <div className="container">
        <div class="offer__headline block-headline"><h3>NAJNOWSZE ARTYKUŁY</h3></div>
        {data.blog.posts.map(post=>{
        return(
          <div className="blog-item">
            <Link to={post.fields.slug}>
            <div className="blog-item__img">
            <Img fluid={post.frontmatter.image.childImageSharp.fluid}/>
            </div>
            </Link>
            <div className="blog-item__title">
            <h2>{post.frontmatter.title}</h2>
            </div>
            <div className="blog-item__info">
            <span>{post.frontmatter.date}</span>
            <span>autor: {post.frontmatter.author}</span>
            </div>
            <div className="blog-item__link">
            <Link className="primary-btn" to={post.fields.slug}>Czytaj więcej..</Link>
            </div>
          </div>
        )
      })}
        </div>
      </section>
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="map__wrapper">
              <div className="contact__map">
                <GoogleMap />
              </div>
            </div>
  
            <div className="contact__info">
              <div className="contact__headline">
                <h2>KONTAKT</h2>
              </div>
              <div className="contact__address">
                <span>ADRES</span>
                <p>
                  ul. Widok 8 lok.5 <br />
                  00-023 Warszawa
                </p>
              </div>
              <div className="contact__email">
                <span>E-MAIL</span>
                <p>biuro@bartoszbator.pl</p>
              </div>
              <div className="contact__telephone">
                <span>TELEFON</span>
                <a href="tel:+48603082948">+ 48 603 082 948</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );

} 

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    blog: allMarkdownRemark(
      limit: 6,
      filter: { fileAbsolutePath: { regex: "/blog/" } },
       sort: {fields: [frontmatter___date], order: DESC}
     )  {
    posts: nodes {
      frontmatter {
        date(formatString:"YYYY-MM-DD")
        title
        author
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields{
        slug
      }
      excerpt
      id
    }
  }
    allOfferJson {
      edges {
        node {
          id
          children {
            id
          }
          items {
            description {
              id
              childMarkdownRemark {
                html
                rawMarkdownBody
              }
            }
            title
            icon
            path
          }
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  
`


// blog:allMarkdownRemark(
//   filter: { fileAbsolutePath: { regex: "/posts/" } },
//   sort: {fields: [frontmatter___date], order: DESC}
// ) {
//   edges {
//     node {
//       frontmatter {
//         author
//         title
//         date
//         image{
//           childImageSharp{
//             fluid(maxWidth: 600){
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//       fields{
//         slug
//       }
//       excerpt
//     }
//   }
// }