import React from 'react'
import {Link} from 'gatsby';
const Button = (props) => {
  return (
    <Link to={props.link}>
      <button class="primary-btn" style={props.style}>
      {props.text}
    </button>
    </Link>
   );
}
 
export default Button;