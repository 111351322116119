import React from 'react';
import { Link } from 'gatsby';

const Offer = props => {
  // const it = props.items;
  // const Items = props.items.map((item, i) => (
  //   <div key={i} className="offer__item">
  //     <img src={item.icon} alt="ikona oferty" />
  //     <h4 id={i}>{item.title}</h4>
  //     <p
  //       dangerouslySetInnerHTML={{
  //         __html: item.description.childMarkdownRemark.html,
  //       }}
  //     />
  //     <Link to={item.path}>
  //       <div className="offer__icon">
  //         <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
  //       </div>
  //     </Link>
  //   </div>
  // ));
  
  return(
    <div className="offer__items">
      <div className="offer__item">
        <img src="pictures/002-hotel.svg" alt="ikona oferty" />
        <h4>NIERUCHOMOŚCI</h4>
        <p>Zajmujemy się wszelkimi sprawami z zakresu obrotu nieruchomościami. W tym wspieramy naszych klientów przy zakupie, sprzedaży, najmie, administrowaniu i zarządzaniu nieruchomościami.</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
      <div className="offer__item">
        <img src="pictures/007-loan.svg" alt="ikona oferty" />
        <h4>POWÓDZTWA FRANKOWE</h4>
        <p>Świadczymy doradztwo prawne w zakresie umów kredytów frankowych. Reprezentujemy konsumentów w sporach sądowych z bankami w ramach tzw. powództw frankowych, zarówno w zakresie unieważniania umów jak i ich tzw. odfrankowienia.</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
      <div className="offer__item">
        <img src="pictures/001-pantheon.svg" alt="ikona oferty" />
        <h4>PRAWO CYWILNE I SPADKOWE</h4>
        <p>Reprezentujemy osoby fizyczne w postępowaniach mediacyjnych oraz sporach sądowych z zakresu prawa cywilnego. W szczególności w zakresie prawa spadkowego, zarówno w postępowaniach w przedmiocie ustalenia spadkobierców, praw do spadku jak i o dział spadku.</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
      <div className="offer__item">
        <img src="pictures/005-family.svg" alt="ikona oferty" />
        <h4>PRAWO RODZINNE</h4>
        <p>Wspieramy osoby fizyczne w sporach rodzinnych. Zarówno w postępowaniu o rozwód jak i o separację czy podział majątku.</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
      <div className="offer__item">
        <img src="pictures/004-suitcase.svg" alt="ikona oferty" />
        <h4>PRAWO PRACY</h4>
        <p>Pomagamy pracownikom w sporach z pracodawcami. Zarówno w zakresie kwestionowania wypowiedzenia warunków pracy i płacy jak i wypowiedzeń umów o pracę czy rozwiązywania umów bez zachowania okresów wypowiedzenia (tzw. dyscyplinarki).</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
      <div className="offer__item">
        <img src="pictures/006-fluctuation.svg" alt="ikona oferty" />
        <h4>PRAWO GOSPODARCZE</h4>
        <p>Doradzamy mały i średnim przedsiębiorcom. Zapewniamy obsługę prawną przy zakładaniu,
przekształcaniu i prowadzeniu wszelkich form działalności gospodarczej.</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
      <div className="offer__item">
        <img src="pictures/003-auction.svg" alt="ikona oferty" />
        <h4>PRAWO ADMINISTRACYJNE</h4>
        <p>Oferujemy wsparcie zarówno osobom fizycznym jak i instytucjom publicznym, w zakresie prawa administracyjnego jak i postępowania administracyjnego oraz sądowoadministracyjnego.</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
      <div className="offer__item">
        <img src="pictures/008-auction-1.svg" alt="ikona oferty" />
        <h4>PRAWO AUTORSKIE</h4>
        <p>Twórcy oraz korzystający z utworów mogą uzyskać fachowe wsparcie w zakresie prawa autorskiego. Reprezentujemy zarówno osoby fizyczne (artystów) jak i prawne oraz instytucje publiczne czy stowarzyszenia i fundacje.</p>
        <Link to="/uslugi">
          <div className="offer__icon">
            <img src="pictures/arrow-pointing-to-right.svg" alt="link do oferty"/>
          </div>
        </Link>
      </div>
  </div>
  ) 
};

export default Offer;
